*
{
    margin: 0;
    padding: 0;
}

#text1
{
    color: #fc8d98;
    font-size: 40px;
    text-align: center;
    margin-top: 450px;
    text-transform: uppercase;
}
#text2
{
    color: #fc8d98;
    font-size: 40px;
    text-align: center;
    margin-top: 100px;
    position: absolute;
    top: 300px;
    text-transform: lowercase;
}
#text3
{
    color: #fc8d98;
    font-size: 40px;
    text-align: center;
    margin-top: 100px;
    position: absolute;
    top: 300px;
    text-transform: lowercase;
}
html,body
{
    background-color: #1e1a20;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}


.section
{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    position: relative;
    font-family: 'nuku1';
    src: url("../static/fonts/nuku1.ttf");
    color: #ffeded;
}

section:nth-child(odd)
{
    justify-content: flex-end;
}